var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "xl": "9",
            "md": "8"
          }
        }, [_c('b-card', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "title"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "title.".concat(k),
                    "name": "".concat(i, " Title"),
                    "rules": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var errors = _ref2.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Title")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Title")
                        },
                        model: {
                          value: _vm.form.title[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.title, k, $$v);
                          },
                          expression: "form.title[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('DatePicker', {
          attrs: {
            "label": 'Publish Date'
          },
          model: {
            value: _vm.form.publish_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "publish_date", $$v);
            },
            expression: "form.publish_date"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "body"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "body.".concat(k),
                    "name": "".concat(i, " Body"),
                    "rules": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref3) {
                      var errors = _ref3.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Body")
                        }
                      }, [_c('editor', {
                        attrs: {
                          "api-key": _vm.tinyApiKey,
                          "init": _vm.tinyConfig
                        },
                        model: {
                          value: _vm.form.body[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.body, k, $$v);
                          },
                          expression: "form.body[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "excerpt"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "excerpt.".concat(k),
                    "name": "".concat(i, " Excerpt"),
                    "rules": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref4) {
                      var errors = _ref4.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Excerpt")
                        }
                      }, [_c('b-form-textarea', {
                        attrs: {
                          "placeholder": "".concat(i, " Excerpt")
                        },
                        model: {
                          value: _vm.form.excerpt[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.excerpt, k, $$v);
                          },
                          expression: "form.excerpt[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "xl": "3",
            "md": "4"
          }
        }, [_c('b-card', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "type",
            "name": "News Type",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "News Type"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "value",
                  "options": _vm.constants,
                  "reduce": function reduce(item) {
                    return item.id;
                  },
                  "placeholder": "News Type"
                },
                model: {
                  value: _vm.form.type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "type", $$v);
                  },
                  expression: "form.type"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "tags",
            "name": "Tags",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Tags"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "multiple": "",
                  "taggable": "",
                  "push-tags": "",
                  "label": "name",
                  "placeholder": "Tags",
                  "options": _vm.tags,
                  "create-option": function createOption(item) {
                    return {
                      name: item,
                      id: item
                    };
                  }
                },
                model: {
                  value: _vm.form.tags,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "tags", $$v);
                  },
                  expression: "form.tags"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "arTags",
            "name": "Arabic Tags",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Arabic Tags"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "multiple": "",
                  "taggable": "",
                  "push-tags": "",
                  "label": "name",
                  "placeholder": "Ar Tags",
                  "options": _vm.tags,
                  "create-option": function createOption(item) {
                    return {
                      name: item,
                      id: item
                    };
                  }
                },
                model: {
                  value: _vm.form.arTags,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "arTags", $$v);
                  },
                  expression: "form.arTags"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "cols": "12"
          }
        }, [_c('legend', {
          staticClass: "bv-no-focus-ring col-form-label pt-0",
          attrs: {
            "tabindex": "-1"
          }
        }, [_vm._v("Featured Image")]), _c('ValidationProvider', {
          attrs: {
            "vid": "featured_image",
            "name": "Featured Image"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('MediaLibraryAttachment', {
                ref: "mediaComponent",
                attrs: {
                  "initial-value": _vm.media_featured_image,
                  "max-items": 1,
                  "name": "featured_image",
                  "headers": _vm.headers,
                  "routePrefix": "media-library",
                  "uploadDomain": _vm.baseURL,
                  "validation-rules": {
                    accept: ['image/webp'],
                    maxSizeInKB: 2048
                  }
                },
                on: {
                  "change": function change($event) {
                    return _vm.getFiles($event);
                  }
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors.featured_image_uuid))])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        })], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1)])], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Is Main ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "true",
            "unchecked-value": "false",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.is_main,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "is_main", $$v);
            },
            expression: "form.is_main"
          }
        })], 1)])], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Is Published ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "true",
            "unchecked-value": "false",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.published,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "published", $$v);
            },
            expression: "form.published"
          }
        })], 1)])], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12 text-center"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }