<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <LocaleTabs
                    :errors="formErrors"
                    name="title"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`title.${k}`"
                        :name="`${i} Title`"
                        rules=""
                      >
                        <b-form-group :label="`${i} Title`">
                          <b-form-input
                            v-model="form.title[k]"
                            :placeholder="`${i} Title`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <DatePicker
                    v-model="form.publish_date"
                    :label="'Publish Date'"
                  />
                </b-col>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="body"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`body.${k}`"
                        :name="`${i} Body`"
                        rules=""
                      >
                        <b-form-group :label="`${i} Body`">
                          <editor
                            v-model="form.body[k]"
                            :api-key="tinyApiKey"
                            :init="tinyConfig"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="excerpt"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`excerpt.${k}`"
                        :name="`${i} Excerpt`"
                        rules=""
                      >
                        <b-form-group :label="`${i} Excerpt`">
                          <b-form-textarea
                            v-model="form.excerpt[k]"
                            :placeholder="`${i} Excerpt`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            xl="3"
            md="4"
          >
            <b-card>
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="type"
                    name="News Type"
                    rules="required"
                  >
                    <b-form-group label="News Type">
                      <v-select
                        v-model="form.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="constants"
                        :reduce="item => item.id"
                        placeholder="News Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="tags"
                    name="Tags"
                    rules="required"
                  >
                    <b-form-group label="Tags">
                      <v-select
                        v-model="form.tags"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        taggable
                        push-tags
                        label="name"
                        placeholder="Tags"
                        :options="tags"
                        :create-option="item => ({ name: item, id: item })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="arTags"
                    name="Arabic Tags"
                    rules="required"
                  >
                    <b-form-group label="Arabic Tags">
                      <v-select
                        v-model="form.arTags"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        taggable
                        push-tags
                        label="name"
                        placeholder="Ar Tags"
                        :options="tags"
                        :create-option="item => ({ name: item, id: item })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12" class="mb-2">
                  <legend
                      tabindex="-1"
                      class="bv-no-focus-ring col-form-label pt-0"
                  >Featured Image</legend>
                  <ValidationProvider
                      v-slot="{ errors }"
                      vid="featured_image"
                      name="Featured Image"
                  >
                    <MediaLibraryAttachment
                        ref="mediaComponent"
                        :initial-value="media_featured_image"
                        :max-items="1"
                        name="featured_image"
                        :headers="headers"
                        routePrefix="media-library"
                        :uploadDomain="baseURL"
                        @change="getFiles($event)"
                        :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 2048 }"
                    />
                    <small class="text-danger">{{ errors.featured_image_uuid }}</small>
                  </ValidationProvider>
                </b-col>
<!--                <b-col cols="12">-->
<!--                  <ValidationProvider-->
<!--                    ref="file_image"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="image"-->
<!--                    name="Logo"-->
<!--                  >-->
<!--                    <ImagePreview-->
<!--                      v-model="form.featured_image"-->
<!--                      :label="'Featured Image'"-->
<!--                    />-->
<!--                    <span class="size-image-span">Image Size 1920 X 1040</span>-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-col>-->
                <b-col cols="12" />
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-card-text class="mb-0">
                      Status
                    </b-card-text>
                    <b-form-checkbox
                      v-model="form.status"
                      checked="true"
                      value="active"
                      unchecked-value="inactive"
                      name="check-button"
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-card-text class="mb-0">
                      Is Main
                    </b-card-text>
                    <b-form-checkbox
                      v-model="form.is_main"
                      checked="true"
                      value="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                    />

                  </div>

                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-card-text class="mb-0">
                      Is Published
                    </b-card-text>
                    <b-form-checkbox
                      v-model="form.published"
                      checked="true"
                      value="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                    />

                  </div>

                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12 text-center">
                  <LoadingButton />
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import Attachment from '@/components/Attachment.vue'
import { serialize } from 'object-to-formdata'
import _ from 'lodash'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  components: {
    MediaLibraryAttachment,
    Attachment,
  },
  mixins: [formMixin],
  data() {
    return {
      constants: [],
      tags: [],
      imageAfterUpload: [],
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      media: null,
      featured_image_uuid: null,
      validationErrors: {
        featured_image: 'The featured image field is required',
      },
      form: {
        title: {
          en: null,
          ar: null,
        },
        body: {
          en: null,
          ar: null,
        },
        excerpt: {
          en: null,
          ar: null,
        },
        tags: null,
        arTags: null,
        type: null,
        featured_image_uuid: null,
        publish_date: null,
        markdown: false,
        status: 'active',
        is_main: false,
        published: false,
      },
      files: [],
    }
  },
  computed: {
    justImages() {
      return ['jpg', 'jpeg', 'png', 'gif', 'bmp']
    },
  },
  created() {
    this.loadConstants()
    this.loadTags()
    if (this.isEdit) {
      this.loadData()
    }
  },
  watch: {
    featured_image(val) {
      if (val) {
        this.form.featured_image_uuid = val
      } else {
        this.form.featured_image_uuid = 'delete'
      }
    },
  },
  methods: {
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form.featured_image_uuid = Object.keys(this.imageAfterUpload[0])[0]
    },
    loadConstants() {
      this.axios.get('/news/constants')
        .then(res => {
          const { data } = res.data
          this.constants = data.types
        })
    },
    loadTags() {
      this.axios.get('/tags/list')
        .then(res => {
          const { data } = res.data
          this.tags = data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'arTags',
            'tags',
            'type',
            'featured_image',
            'publish_date',
            'markdown',
            'status',
            'is_main',
            'published',
          ])
          this.form = {
            ...this.form,
            title: data.title_value,
            body: data.body_value,
            excerpt: data.excerpt_value,
            featured_image_uuid: data.media_featured_image?.uuid || null,
          }
          if (data.media_featured_image?.uuid){
            this.media_featured_image = data.media_featured_image
            this.featured_image_uuid = data.media_featured_image?.uuid || null
            let arr = []
            const obj = {
              attributes: this.media_featured_image,
              clientValidationErrors: [],
              client_preview: data.media_featured_image?.original_url,
              upload: {},
            }
            arr.push(obj)
            this.$refs.mediaComponent.mediaLibrary.changeState(state => {
              state.media = arr
            })
          }
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.featured_image instanceof File)) {
        delete $form.featured_image
      }
      return $form
    },
    removeFile(index) {
      const file = this.files[index]
      this.files[index] = {
        ...file,
        file: 'delete',
      }
      this.$refs[`files${index}`][0].remove()
    },
    addFile() {
      this.files.push({
        id: null,
        name: null,
        title: null,
        file: null,
      })
    },
    onChangeFile(e, index) {
      const file = e
      this.files[index].name = file.name
      this.files[index].file = file
    },
  },
}
</script>
<style scoped>
.size-image-span {
  position: relative;
  top:-10px;
  font-size: 12px;
}
</style>
