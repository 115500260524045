var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ImagePreview', {
    attrs: {
      "label": _vm.label,
      "hint": "Image size should be at least 1200x800px (16:9) and less than 2mb"
    },
    model: {
      value: _vm.value.file,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "file", $$v);
      },
      expression: "value.file"
    }
  }), _c('validation-provider', {
    attrs: {
      "tag": "div",
      "vid": "name",
      "name": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Name"
          },
          model: {
            value: _vm.value.title,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "title", $$v);
            },
            expression: "value.title"
          }
        }), _c('small', {
          staticClass: "text-danger mb-2 d-block"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }