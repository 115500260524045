<template>
  <div>
    <ImagePreview
      v-model="value.file"
      :label="label"
      hint="Image size should be at least 1200x800px (16:9) and less than 2mb"
    />
    <validation-provider
      #default="{ errors }"
      tag="div"
      vid="name"
      name="name"
      rules="required"
    >
      <b-form-group label="Name">
        <b-form-input
          v-model="value.title"
          placeholder="Name"
        />
        <small class="text-danger mb-2 d-block">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import ImagePreview from './ImagePreview.vue'

export default {
  components: {
    ImagePreview,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        file: null,
        name: null,
        title: null,
      }),
    },
    label: {
      type: String,
      default: 'Attachment',
    },
  },
}
</script>
